<template>
    
    <h1>Altres catàlegs d'autoritat</h1>

    <div class="alert alert-light">
    <ul>
    <li><a href="https://dades.bnc.cat/autoritats/lenoti"><i>Llista d’encapçalaments de noms i títols de la Biblioteca de Catalunya (LENOTI)</i></a>
    </li>
    <li><a href="https://dades.bnc.cat/autoritats/lemac"><i>Llista d’encapçalaments de matèria en català (LEMAC)</i></a>
    </li>
    <li><a href="https://authorities.loc.gov/"><i>Library of Congress Authorities </i></a>
    </li>
    <li><a href="http://catalogo.bne.es/uhtbin/authoritybrowse.cgi"><i>Catálogo
    de autoridades</i></a> de la Biblioteca Nacional d’Espanya
    </li>
    </ul>

    </div>
    <div class="alert alert-light">
        <span class="h6">El Catàleg d’autoritats de noms i títols de Catalunya participa en els projectes següents:</span>
        <ul>
            <li><a href="https://viaf.org/" target="_blank">VIAF (Virtual International Authority File)</a>
    <p>És un projecte conjunt de diverses biblioteques nacionals i gestionat per l'Online Computer Library Center (OCLC).  L'objectiu és vincular els catàlegs d'autoritat nacionals en un sol fitxer d'autoritats virtual. Les dades estan disponibles en línia per a la investigació i l'intercanvi de dades.</p></li>
            <li><a href="https://data.cerl.org/thesaurus/" target="_blank">CERL Thesaurus</a><p>Està gestionat pel Consortium of European Research Libraries i inclou diversos catàlegs d’autoritats d’àmbit europeu. Conté dades referent a noms de lloc, entitats, impressors i autors compresos entre el 1450 i el 1830. És una de les eines essencials disponible en línia per als investigadors del llibre antic.</p></li>
        </ul>
    </div>
</template>

<style scoped>
.alert-light {
    border: solid 1px gray;
    background-color: white;
}
</style>