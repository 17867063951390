<template>
<h1>Com esdevenir participant del CANTIC?</h1>

<p>Aquí teniu la sol·licitud en línia per a totes aquelles biblioteques que vulguin participar en el CANTIC.
<br><br>
Després de la presentació de la sol·licitud, el personal del Servei de Normalització Bibliogràfica de la Biblioteca de Catalunya es posarà en contacte amb la institució per establir les línies de col·laboració i formalitzar un conveni de mutu acord.
<br><br>
Envieu qualsevol dubte o pregunta relacionades amb el procés de sol·licitud a <a href="snb@bnc.cat">snb@bnc.cat</a></p>
<br />
<h2>Sol·licitud</h2>
<div class="alert alert-danger" role="alert" v-if="errors.length">
    <ul>
        <li v-for="(error, i) in errors" :key="i">{{ error }}</li>
    </ul>
</div>
<div class="alert alert-danger" role="alert" v-if="sendError">
    <p>Hi ha hagut un error en l'enviament de les dades.</p>
    <p>Torneu a provar més endavant.</p>
    <p>Si l'error persisteix contacteu amb <a href="mailto:smb@bnc.cat">snb@bnc.cat</a></p>
</div>
<form>
    <div class="mb-3">
        <label for="input1" class="form-label">Nom de la institució<b>*</b>:</label>
        <input v-model="input1" type="text" id="input1" class="form-control" placeholder="" required />
    </div>
    <div class="mb-3">
        <label for="input2" class="form-label">Adreça<b>*</b>:</label>
        <input v-model="input2" type="text" id="input2" class="form-control" placeholder="" required />
    </div>
    <div class="mb-3">
        <label for="input3" class="form-label">Codi MARC 21 de la institució (1):</label>
        <input v-model="input3" type="text" id="input3" class="form-control" placeholder="" />
    </div>
    <div class="mb-3">
        <label for="input4" class="form-label">Normativa emprada (2):</label>
        <input v-model="input4" type="text" id="input4" class="form-control" placeholder="" />
    </div>
    <div class="mb-3">
        <label for="input5" class="form-label">Sistema automatitzat de catalogació local:</label>
        <input v-model="input5" type="text" id="input5" class="form-control" placeholder="" />
    </div>
    <div class="mb-3">
        <label for="input6" class="form-label">Àrees temàtiques del fons de la institució:</label>
        <input v-model="input6" type="text" id="input6" class="form-control" placeholder="" />
    </div>
    <div class="mb-3">
        <label for="input7" class="form-label">Nombre anual estimat de registres d’autoritat:</label>
        <input v-model="input7" type="number" id="input7" class="form-control" placeholder="" />
    </div>
    <div class="mb-3">
        <label for="input8" class="form-label">Nombre de bibliotecaris que cal formar:</label>
        <input v-model="input8" type="number" id="input8" class="form-control" placeholder="" />
    </div>
    <hr>
    <div class="mb-3">
        <label for="input9" class="form-label">Nom del/de la director/a de la institució<b>*</b>:</label>
        <input v-model="input9" type="text" id="input9" class="form-control" placeholder="" required />
    </div>
    
    <div class="mb-3">
        <label for="input10" class="form-label">Tel.:</label>
        <input v-model="input10" type="yel" id="input10" class="form-control" placeholder="" />
    </div>
    <div class="mb-3">
        <label for="input11" class="form-label">Fax:</label>
        <input v-model="input11" type="tel" id="input11" class="form-control" placeholder="" />
    </div>
    <div class="mb-3">
        <label for="input12" class="form-label">Correu electrònic<b>*</b>:</label>
        <input v-model="input12" type="email" id="input12" class="form-control" placeholder="" required />
    </div>
    <hr>
    <div class="mb-3">
        <label for="input13" class="form-label">Nom de la persona de contacte per al CANTIC<b>*</b>:</label>
        <input v-model="input13" type="text" id="input13" class="form-control" placeholder="" />
    </div>
    <div class="mb-3">
        <label for="input14" class="form-label">Càrrec:</label>
        <input v-model="input14" type="text" id="input14" class="form-control" placeholder="" />
    </div>
    <div class="mb-3">
        <label for="input15" class="form-label">Tel.:</label>
        <input v-model="input15" type="tel" id="input15" class="form-control" placeholder="" />
    </div>
    <div class="mb-3">
        <label for="input16" class="form-label">Fax:</label>
        <input v-model="input16" type="tel" id="input16" class="form-control" placeholder="" />
    </div>
    <div class="mb-3">
        <label for="input17" class="form-label">Correu electrònic<b>*</b>:</label>
        <input v-model="input17" type="email" id="input17" class="form-control" placeholder="" required />
    </div>
    <div class="mb-3">
        <vue-recaptcha siteKey="6Le8a8MSAAAAAFJrlaVHs1rT-V75igT3BiRgg5l7"
            size="normal" 
            theme="light"
            :tabindex="0"
            @verify="recaptchaVerified"
            @expire="recaptchaExpired"
            @fail="recaptchaFailed"
            ref="vueRecaptcha">
        </vue-recaptcha>
    </div>
    <button type="submit" class="btn btn-primary" @click="send" @click.prevent>Sol·licitar</button>
</form>
<ul>
    <li><em>Les dades personals són confidencials i són tractades d'acord amb la Llei Orgànica 15/1999, de 13 de desembre, de Protecció de dades de caràcter personal.</em></li>
    <li>(*) Els camps són obligatoris</li>
    <li>(1) Si no té assignat aquest codi,  pot demanar-lo a la Library of Congress  mitjançant el formulari següent : <a href="http://www.loc.gov/marc/organizations/form-spa.html" target="_blank">http://www.loc.gov/marc/organizations/form-spa.html</a></li>
    <li>(2) Normativa emprada per a l’establiment dels encapçalaments de nom de persona, entitat i títol</li>
</ul>
</template>
<script>
import vueRecaptcha from 'vue3-recaptcha2';
export default {
    components: { vueRecaptcha },
    data() {
        return {
            captchaOk: false,
            sendError: false,
            input1: '',
            input2: '',
            input3: '',
            input4: '',
            input5: '',
            input6: '',
            input7: '',
            input8: '',
            input9: '',
            input10: '',
            input11: '',
            input12: '',
            input13: '',
            input14: '',
            input15: '',
            input16: '',
            input17: '',
            errors: []

        }
    },
    methods: {
        recaptchaVerified: function(res) {
            console.log(res);
            this.captchaOk = true;
        },
        recaptchaExpired: function() {
            this.$refs.vueRecaptcha.reset();
            this.captchaOk = false;
        },
        recaptchaFailed: function() {
            this.captchaOk = false;
        },
        send: function() {
            if (!this.captchaOk) {
                console.log("no captcha verified");
            } else {
                this.errors = [];
                if (this.input1.trim().length == 0)
                    this.errors.push("Heu de posar el nom de la institució");
                if (this.input2.trim().length == 0)
                    this.errors.push("Heu de posar l'adreça de la institució");
                if (this.input9.trim().length == 0)
                    this.errors.push("Heu de posar el director o la directora de la institució");
                if (this.input12.trim().length == 0)
                    this.errors.push("Heu de posar l'adreça de de correu del director/a");
                if (this.input13.trim().length == 0)
                    this.errors.push("Heu de posar el nom de la persona de contacte");
                if (this.input17.trim().length == 0)
                    this.errors.push("Heu de posar l'adreça de correu de la persona de contacte");
                if (!this.errors.length) {
                    this.axios.post(`${process.env.VUE_APP_API_URL}/member`,
                        {input1: this.input1, input2: this.input2, input3: this.input3, input4: this.input4, 
                         input5: this.input5, input6: this.input6, input7: this.input7, input8: this.input8,
                         input9: this.input9, input10: this.input10, input11: this.input11, input12: this.input12,
                         input13: this.input13, input14: this.input14, input15: this.input15, input16: this.input16,
                         input17: this.input17 } ,
                        { "headers": { "authorization": `bearer ${process.env.VUE_APP_API_KEY}`, "Content-Type": "application/json" } }).
                        then( () => {
                            console.log();
                            this.$router.push({ name: 'enviat' });
                        }).
                        catch( () => {
                            window.scrollTo(0, 0);
                            this.sendError = true;
                        });
                }
                window.scrollTo(0, 0);
            }
        }
    }
}
</script>