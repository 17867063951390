<template>

<div>
    <h1>{{ title }}</h1>
    <div class=form-group>
      <div class="row">
        <div class="col-md-3 col-sm-11">
          <label for="cerca" class="visually-hidden">Text a cercar</label>
          <input id="cerca" type="search" :placeholder="placeHolder" v-model="text" class="form-control" @keyup="keyPressed" @search="niceClear" />
        </div>
        <div class="col-md-1 col-sm-1">
          <button  ref="helpBtn" id="popover-target-1" class="btn btn-primary" @click="toggleHelp">
            <span class="h5">&#x1F6C8;</span>
          </button>
        </div>
        <div class="col-md-3 col-sm-4" :class="searchMethod == 2 ? 'disabled' : ''">
            
            <fieldset>
              <legend class="visually-hidden">Seleccioneu si cerqueu a la LEMAC o termes en anglès de la LSCH</legend>
              <div class="form-check"><input type="radio" class="form-check-input" id="scope1" v-model="scope" name="scope" value="np"><label for="scope1" class="form-check-label">Noms personals</label></div>
              <div class="form-check"><input type="radio" class="form-check-input" id="scope2" v-model="scope" name="scope" value="nc"><label for="scope2" class="form-check-label">Noms corporatius</label></div>
              <div class="form-check"><input type="radio" class="form-check-input" id="scope3" v-model="scope" name="scope" value="gj"><label for="scope3" class="form-check-label">Geogràfics jurisdiccionals</label></div>
              <div class="form-check"><input type="radio" class="form-check-input" id="scope4" v-model="scope" name="scope" value="nt"><label for="scope4" class="form-check-label">Títols</label></div>
            </fieldset>
            
        </div>
        <div class="col-md-3 col-sm-5">
            
            <fieldset>
              <legend class="visually-hidden">Seleccioneu si cerqueu sobre índex o per paraula clau</legend>
              <div class="form-check"><input type="radio" class="form-check-input" id="method1" v-model="searchMethod" name="search-method" value="1" /><label for="method1" class="form-check-label">Cerca sobre índex</label></div>
              <div class="form-check"><input type="radio" class="form-check-input" id="method2" v-model="searchMethod" name="search-method" value="2" /><label for="method2" class="form-check-label">Cerca per paraula clau</label></div>
            </fieldset>
        </div>
        <div class="col-md-2 col-sm-4">
          <label for="items-per-page" class="d-inline-block">Entrades per pàgina:&nbsp;&nbsp;</label>
          <select id="items-per-page" v-model.number="perPage" class="form-select form-control-sm d-inline-block">
            <option value="15">15</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
    </div>
    <div ref="help" class="popover" :style="displayHelp">
      <h2 class="popover-header">Ajuda de cerca <button class="btn btn-sm float-end" style="margin-top: -4px;" @click="hideHelp=true">&#10006;</button></h2>
      <ul>
        <li>La cerca no és sensible a majúscules ni minúscules, així com a diacrítics.</li>
        <li>Si cerqueu per índex, la cerca us posicionarà en la primera coincidència dels caràcters que heu escrit.</li>
        <li>La sintaxi en la cerca per índex és directe, el que escriviu és el que es cercarà, excloent signes de puntuació.</li>
        <li>Lcerca només es relitza en l'índex que hagueu sel·lecionat.</li>
        <li style="list-style-type:none;"><hr/></li>
        <li>Si cerqueu per paraula clau, es cercaran totes les paraules que heu escrit. Aquesta cerca es realitzarà sobre tots els índexs i elements relacionats</li>
        <li>La sintaxi de cerca és la següent:
          <ul>
            <li>És cercaran totes les paraules que es trobin separades per un espai. Ex: <i>miguel cervantes</i>, cercarà les entrades amb miguel i cervantes</li>
            <li>Si poseu una frases entre cometes dobles " es cercaran les paraules incloses i en aquell mateix ordre. Ex: <i>"cervantes saavedra"</i></li>
            <li>Podeu fer un cerca de qualsevol paraula (o booleà) afegint "o" al criteri de cerca. Ex: <i>cervantes o quixot"</i></li>
            <li>Podeu excloure un terme de la cerca posant un signe "-" a l'inici de la paraula. Ex: <i>quixot -cervantes"</i></li>
            <li>Podeu fer servir el caràcter comodí "*" per auto completar qualsevol terme. Ex: <i>saa*</i></li>
            <li>Podeu agrupar criteris de cerca emprant parèntesi. Ex: <i>(quixot o quijote) "cervantes saa*"</i></li>
          </ul>
        </li>
      </ul>
    </div>
    <div id="encapsalaments">

        <div class="row">
          <div class="col-9 my-bg-primary text-white">Encapçalament</div>
          <div class="col-3 my-bg-primary text-white">Tipus</div>  
        </div>

        <div class="row" v-for="(entry, index) in entries" :key="entry['id']" :class="[ (index % 2 && $store.state.offset != entry['id']) ? 'my-bg-light' : '', $store.state.offset == entry['id'] ? 'my-bg-success text-white !important ' : '']" >
          <div class="col-9"><router-link :to="{name: 'detall',  params: { recordId: entry['id_registre'], page: $store.state.page, index: (entry['id']||'0') }}"><span v-html="highlighted(entry['encapsalament'])" :class="[ $store.state.offset == entry['id'] ? 'text-white' : '']"/></router-link></div>
          <div class="col-3">{{ getType(entry['etiqueta']) }}</div>  
        </div>

    </div>
    
    <Paginacio @page-changed="pageChanged" :page="parseInt($store.state.page)" :perPage="perPage" :total="parseInt($store.state.total)"/>
</div>
    
</template>
<script>
var unidecode = require('unidecode');
import Paginacio from '../components/Paginacio.vue';
var timer = 0;

export default {
    name: 'Index',
    components: { Paginacio },
    data() {
        return {
            entries: [],
            perPage: 15,
            text: '',
            scope: 'np',
            searchMethod: 1,
            filter: '',
            applyFilter: false,
            loading: false,
            updateOffset: true,
            hideHelp: true,
            page: 1
        }
    },
    created() {
        this.loading = true;

        this.searchMethod = this.$store.state.searchMethod;
        this.perPage = this.$store.state.perPage;
        this.scope = this.$store.state.scope;
        this.text = this.$store.state.text;
        this.page = this.$store.state.page;
        this.setFilter();
        this.loading = false;
        this.getEntries();
        
    },
    methods: {
        setFilter: function() {
          if (this.searchMethod == 1) {
            this.filter = this.text.trim();
            if (this.filter.length)
              this.applyFilter = true;  
            else
              this.niceClear();
          } else {
            let quotes_count = (this.filter.match(/"/g) || []).length;
            //this.filter = unidecode(this.text.trim());
            this.filter = this.text.trim();
            if (this.filter.length >= 3 && (quotes_count % 2) == 0)
              this.applyFilter = true;
          }
        },
        keyPressed: function(e) {
          this.$store.commit('setText', this.text);
          if (timer) {
            clearTimeout(timer);
            timer = 0;
          }
          
          this.setFilter();
          if (!this.applyFilter)
            this.filter = '';
          else {
            if (e.keyCode == 13) {
              this.getEntries();
            } else {
              clearTimeout(timer);
              timer = setTimeout(this.getEntries, 500);
            }
          }
        },
        getEntries: function() {
            let url = (this.searchMethod == 1) ? `${process.env.VUE_APP_API_URL}/index/${this.scope}/${this.page}/${this.perPage}/${this.filter}` :
                                               `${process.env.VUE_APP_API_URL}/search/${this.page}/${this.perPage}/${this.filter}`
            
            this.axios.get(url, { "headers": { "authorization": `bearer ${process.env.VUE_APP_API_KEY}` } } )
                .then((res) => {
                    this.entries = res.data.data;
                    this.$store.commit('setTotal', res.data.total)

                    if (this.searchMethod == 1)
                      this.$store.commit('setPage', res.data.active);
                    if (this.updateOffset)
                        this.$store.commit('setOffset', res.data.offset);
                    this.updateOffset = true;
                    if ((this.perPage * this.$store.state.page) > this.$store.state.total) {
                      this.$store.commit('setPage', Math.ceil( this.$store.state.total / this.perPage));
                    }
                })
                .catch((err) => {
                    console.log("Error obtenint registres:", err);
                });
              this.applyFilter = false;
        },
        highlighted: function(text) {
            text = text.replace("<original-index>", "&nbsp;<em>[");
            text = text.replace("</original-index>", "]</em>");
            if (this.searchMethod == 1 || this.text == '') return text;
            var quotes_count = (text.match(/"/g) || []).length;
            if ((quotes_count % 2) !== 0) return text;
            let clean = unidecode(this.text.replace(/[.,/#!$%^&*;:{}=+_`´'~()]/g, " "));
            clean = clean.replace(/\s+/g, ' ');
            let words = clean.match(/-?\w+|-?"[^"]+"/g);
            for(let i = words.length - 1; i >= 0; i--) {
                if(words[i][0] == "-")
                words.splice(i, i);
                else
                words[i] = words[i].replace(/"/g, '');
            }

            let transliterated_text = unidecode(text);
            let highlightedPositions = [];
            for(let word of words) {
                if (word == 'o') continue;
                var needle = new RegExp(word, "ig");
                var r = needle.exec(transliterated_text);
                if (r == null)
                continue;
                var start = r.index;
                var end = start + r[0].length;
                highlightedPositions.push([start, end]);
                //text = [text.slice(0,start), '<span class="text-danger">', text.slice(start, end), '</span>', text.slice(end)].join('');
            }
            if(highlightedPositions.length) {
                highlightedPositions.sort(function(x, y) {
                if(x[0] < y[0]) return 1;
                if(x[0] > y[0]) return -1;
                return 0;
                });
                for(let pos of highlightedPositions) {
                text = [text.slice(0,pos[0]), '<b>', text.slice(pos[0], pos[1]), '</b>', text.slice(pos[1])].join('');
                
                }
            }
            console.log(text);
            return text;
        },
        niceClear: function() {
            if(this.text.length === 0) {
                this.$store.commit('setOffset', 0);
                this.$store.commit('setPage', 1);
                this.$store.commit('setTotal', 0);
                this.$store.commit('setText', '');
                this.filter = "";
                
                this.getEntries();
            }
        },
        getType: function (label) {
          if (label.startsWith('1'))
            return 'Acceptat';
          if (label.startsWith('5'))
            return 'Relacionat';
          else
            return 'No acceptat';
        },
        pageChanged: function(newPage) {
          this.page = newPage;
          if (this.searchMethod == 1)
            this.filter = '';
          this.updateOffset = false;
          this.getEntries();
        },
        toggleHelp: function() {
          this.hideHelp = !this.hideHelp;
        }
    },
    computed: {
        title: function () {
            let t = 'Índex'
            switch(this.scope) {
                case 'np':
                    t += ' de noms personals';
                    break;
                case 'nc':
                    t += ' de noms corporatius';
                    break;
                case 'nt':
                    t += ' de títols';
                    break;
                case 'gj':
                    t += ' de noms geogràfics';
                    break;
            }
            return t;
        },
        placeHolder: function () {
            return this.searchMethod == 1 ? "Cerca a l'index ..." : "Cerca per paraula ...";
        },
        displayHelp: function() {
          let top = 0, left = 0;
          if ('helpBtn' in this.$refs) {
            top = this.$refs.helpBtn.getBoundingClientRect().bottom;
            left = (window.innerWidth - this.$refs.help.clientWidth)  / 2;
          }
            
            
          return (this.hideHelp ? "visibility: hidden;" : `position: absolute; display:block; top: ${top}px; left: ${left}px`)
        }
    },
    watch: {
        page() {
            if (!this.loading) 
              this.$store.commit('setPage', this.page);
        },
        scope() {
            if (!this.loading) {
                this.$store.commit('setOffset', 0);
                this.$store.commit('setPage', 1);
                this.$store.commit('setTotal', 0);
                this.$store.commit('setScope', this.scope);

                this.setFilter();
                this.getEntries();
            }
        },
        perPage(newVal, oldVal) {
            if (!this.loading) {
                this.$store.commit('setPerPage', this.perPage);
                let newPage = Math.ceil(((this.$store.state.page-1) * oldVal + 1) / newVal);
                if (newPage < 1) newPage = 1;
                this.$store.commit('setPage', newPage);

                this.setFilter();
                this.getEntries();
            }
        },
        searchMethod() {
            if (!this.loading) {
                this.$store.commit('setOffset', 0);
                this.$store.commit('setPage', 1);
                this.$store.commit('setTotal', 0);
                this.$store.commit('setSearchMethod', this.searchMethod);

                this.setFilter();
                this.getEntries();
          }
        }

    }

}
</script>

<style scoped>

div.disabled
{
  pointer-events: none;
  opacity: 0.5;
}

.popover {
  max-width: 75%;
}

.my-bg-light {
  background-color: #f5f5f5;
}
.my-bg-primary {
  background-color: #00796B;
}
.my-bg-success {
  background-color: #26A69A;
}

#encapsalaments a {
    text-decoration: none;
}
#encapsalaments a:hover {
    font-weight: bold;
}

b {
  border: solid 1px #26A69A !important;
}


</style>